<template>
    <div id="config-printed_labels" class="container-fluid">
        <div class="row">
            <titles title="Configuración" subtitle="Etiquetas impresas" ></titles>
            <title-tabs :array_titletabs="title_printed_labels"></title-tabs>
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">
                    <span class="text-black opensans-semibold ki--description-sm">Nueva etiqueta</span>
                    <div class="col-12 p-0 bg-lightgray mt-2">
                        <div class="row p-2">
                            <div class="col-6">
                                <div class="row p-0">
                                    <div class="col-6 col-xxl4 p-0">
                                        <ki-input label="Código:" type="number" placeholder="Ingrese código" full class="opensans-semibold"> </ki-input>
                                    </div>
                                    <div class="col-6 col-xxl-4 p-0">
                                        <div class="d-flex pl-2 align-items-end h-100">
                                            <button class="pills bg-white"> 
                                                <div class="d-flex align-items-center">
                                                    <img :src="icon_search" style="max-width:18px;" class="">
                                                    <span class="pl-2">Buscar</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-6 col-xxl-4 p-0">
                                        <ki-input label="Cantidad:" type="number" placeholder="00:00" full class="opensans-semibold"> </ki-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 p-0" >
                                <div class="row p-0">
                                    <div class="col-6 col-xxl-4 p-0 pr-2">
                                        <ki-select 
                                            v-model="family"
                                            label="Agregar por familia:" 
                                            :options="[{id: 1, label: 'Valor1'}, {id: 2, label: 'Value2'}]" 
                                            full class="opensans-semibold">
                                        </ki-select>
                                    </div>
                                    <div class="col-6 col-xxl-4 p-0 pr-2">
                                        <ki-select 
                                            v-model="provider"
                                            label="Agregar proveedor:" 
                                            :options="[{id: 1, label: 'Value1'}, {id: 2, label: 'Value2'}]" 
                                            hidden_option="Seleccione"
                                            full class="opensans-semibold">
                                        </ki-select>
                                    </div>
                                    <div class="col-4 p-0 d-flex align-items-end pt-xl-3">
                                        <button class="pills bg-white px-4 py-0"
                                            style="height: 30px;">
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="text-black opensans-semibold ki--description-sm mt-3" >Listado de etiquetas y ajuste de etiquetas</span>
                    <div class="col-12 p-0">
                        <div class="row p-0">
                            <div class="col-xl-3 col-md-4 pl-0 pr-2">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px; margin-top: 5px;">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">Tipo de etiqueta</span>
                                    <div class="mt-2">
                                        <div class="d-flex justify-content-start align-items-center pl-1" v-for="(item_typelabel, index_typelabel) in array_typelabel" :key="index_typelabel">
                                            <input type="radio" :id="`typeproduct_${index_typelabel}`" name="typelabel" :checked="item_typelabel.check">
                                            <label :for="`typeproduct_${index_typelabel}`" class=" title_selects label_checkbox mt-1 ml-2">{{item_typelabel.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 pl-0 pr-2">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px; margin-top: 5px;">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">Tipo de hoja</span>
                                    <div class="mt-2">
                                        <div class="d-flex justify-content-start align-items-center pl-1" v-for="(item_typesheet, index_typesheet) in array_typesheet" :key="index_typesheet">
                                            <input type="radio" :id="`typeproduct_${index_typesheet}`" name="typesheet" :checked="item_typesheet.check">
                                            <label :for="`typeproduct_${index_typesheet}`" class=" title_selects label_checkbox mt-1 ml-2">{{item_typesheet.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 pl-0 pr-2">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px; margin-top: 5px;">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">Código a imprimir</span>
                                    <div class="mt-2">
                                        <div class="d-flex justify-content-start align-items-center pl-1" v-for="(item_codeprinter, index_codeprinter) in array_codeprinter" :key="index_codeprinter">
                                            <input type="radio" :id="`typeproduct_${index_codeprinter}`" name="codeprinter" :checked="item_codeprinter.check">
                                            <label :for="`typeproduct_${index_codeprinter}`" class=" title_selects label_checkbox mt-1 ml-2">{{item_codeprinter.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 pl-0 pr-2">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px;  margin-top: 5px;">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">Precio a imprimir</span>
                                    <div class="row p-0 mt-2">
                                        <div class="col-6 p-0" v-for="(item_priceprinter, index_priceprinter) in array_priceprinter" :key="index_priceprinter">
                                            <div class="d-flex justify-content-start align-items-center pl-1">
                                                <input type="radio" :id="`typeproduct_${index_priceprinter}`" name="priceprinter" :checked="item_priceprinter.check">
                                                <label :for="`typeproduct_${index_priceprinter}`" class=" title_selects label_checkbox mt-1 ml-2">{{item_priceprinter.name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-4 p-0">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px; width: 97.1%; margin-top: 5px;">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">IVA</span>
                                    <div class="mt-2 d-flex">
                                        <ki-checkbox :label="item_iva.name" fill class="text-black opensans-semibold" style="font-size:13px" v-for="(item_iva, index_iva) in array_iva" :key="index_iva" :value="item_iva.check"></ki-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 mt-2">
                        <ki-table :data="data_table" 
                            :allow_headers="headers_allow" 
                            no_pagination color_headers="white" color_body="white">
                            <template v-slot:select="data">
                                <div class="pl-4">
                                    <input type="radio" :checked="data.value">
                                </div>
                            </template>
                        </ki-table>
                    </div>
                    <div class="col-12 p-0">
                        <div class="row p-0 col d-flex justify-content-end align-items-center">
                            <div class="p-0">
                                <button class="pills bg-primary-dark px-4 py-2 mt-3 text-white">Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../../components/Titles.vue'
    import TitleTabs from '../../components/TitleTabs.vue'

    //Icons
    import icon_search from '../../assets/icons/icon_search.png'
export default {
    name: 'ki-config-printed_labels',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            title_printed_labels: [
                { name: 'Etiquetas impresas', selected: true },
            ],
            titletab_active: null,
            icon_search,
            array_typelabel:[
                { name: 'Productos', check: true },
                { name: 'Cabecera', check: false },
                { name: 'Impresora de solo texto', check: false },
            ],
            array_typesheet:[
                { name: '30 etiquetas por hoja', check: true },
                { name: '80 etiquetas por hoja', check: false }
            ],
            array_codeprinter:[
                { name: 'Código interno (ID)', check: true },
                { name: 'Código de barras', check: false },
                { name: 'Código de proveedor', check: false }
            ],
            array_priceprinter:[
                { name: 'Precio 1', check: true },
                { name: 'Precio 2', check: false },
                { name: 'Precio 3', check: false },
                { name: 'Precio 4', check: false },
                { name: 'Precio 5', check: false },
                { name: 'Precio 6', check: false },
            ],
            array_iva:[
                { name: 'No incluir IVA', check: true }
            ],
            headers_allow: [
                { key: 'select', label: "Selec.", width: '80'}, 
                { key: 'code', label: "Código interno"}, 
                { key: 'name', label: "Nombre"}, 
                { key: 'quantity', label: "Cantidad"}, 
                { key: 'family', label: "Familia"}, 
                { key: 'product', label: "Producto"}, 
            ],
            data_table: [
                { select: true, code: '-', name: '-', quantity: '-', family: '-', product: '-' },
                { select: false, code: '-', name: '-', quantity: '-', family: '-', product: '-' },
                { select: false, code: '-', name: '-', quantity: '-', family: '-', product: '-' },
                { select: false, code: '-', name: '-', quantity: '-', family: '-', product: '-' },
                { select: false, code: '-', name: '-', quantity: '-', family: '-', product: '-' },
                { select: false, code: '-', name: '-', quantity: '-', family: '-', product: '-' }, 
            ],
            family: 'Seleccione',
            provider: 'Seleccione'
        };
    },
    methods: {
        
    },
    created(){
        for (let i in this.title_printed_labels) {
            if(this.title_printed_labels[i].selected){
                this.titletab_active = this.title_printed_labels[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>